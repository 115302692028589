import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import Img from "gatsby-image"

class AboutPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO
          title="What's On | Kingdom Kids"
          description="Due to COVID-19 restrictions, we are now meeting at gracehouse Church on Wednesday and Thursday afternoons from 4pm - 6pm."
        />
        <Nav />
        <div className="kdk-nav-bg kdk-top-section"></div>

        <section className="kdk-parks">
          <div className="container">
            <h1>We'd love to have you come</h1>
            <div className="container-sm">
              <Img
                fluid={data.whatsOn.childImageSharp.fluid}
                alt={"Whats On Team Shot"}
              />
            </div>
            <div className="container-sm">
              <p>
                Our events are a great way for your kids to get connected with
                our team on a weekly basis.
              </p>
              <p>
                During these events we run games (Red Rover seems to be a
                never-ending favourite), bring a message from the Bible, have
                afternoon tea and build community.
              </p>
              <p>
                Our events are for ages 2-12, but older siblings are more than
                welcome. There's always some form of basketball going down.
              </p>
            </div>
            <div className="columns">
              <div className="column">
                <h3>wednesdays</h3>
                <p>
                  4pm - 6pm
                  <br />
                  Ewing Park (Train Park)
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://goo.gl/maps/3QkFKWAVaV9kAj4P9"
                  className="btn-default"
                >
                  Get directions
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query ParksQuery {
    site {
      siteMetadata {
        title
      }
    }
    whatsOn: file(relativePath: { eq: "whats-on-team-shot.jpeg" }) {
      childImageSharp {
        fluid(traceSVG: { color: "#fff100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
